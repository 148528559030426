import React from 'react'
import Head from '../components/head'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import '../styles/project.css'

export const data = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`

//check to swap with stateless component
class Project extends React.Component {
  render() {
    return (
      <Layout page="More Work">
        <Head title={this.props.data.markdownRemark.frontmatter.title} />
        <article
          className="project-container"
          dangerouslySetInnerHTML={{
            __html: this.props.data.markdownRemark.html,
          }}
        ></article>
      </Layout>
    )
  }
}

export default Project
